import * as React from "react";
import "../../gatsby-browser.js";
import Into from "../static/img/intro.png";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
// import HowAbout from "../component/Howwedo/HowAbout"
// import HowTech from "../component/Howwedo/HowTech";
import { motion } from "framer-motion";
import { lan } from "../lan/index.js";
import Illustration from "../static/img/HowWeDo/illustration.svg";
import Methodology from "../static/img/HowWeDo/methodology.svg";
import MetaTags from "../component/MetaTags.jsx";
// import axios from 'axios';
// import Load from "../static/img/load/load.gif";
// import {url} from "../url/url";
// import HowMethod from "../component/Howwedo/HowMethod";

class Howwedo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      // loading: true
    };
  }

  // componentDidMount() {

  //     if(typeof window !== 'undefined') {
  //         axios({
  //             method: 'GET',
  //             url: url + '/howwedos?_locale=' + localStorage.getItem('lan'),
  //         })
  //             .then((result) => {
  //                 this.setState({
  //                     data: result.data,
  //                     loading: false
  //                 })
  //             })
  //             .catch((error) => {
  //                 this.setState({
  //                     loading: false
  //                 })
  //             })
  //     }else {
  //         axios({
  //             method: 'GET',
  //             url: url + '/howwedos?_locale=' + "en",
  //         })
  //             .then((result) => {
  //                 this.setState({
  //                     data: result.data,
  //                     loading: false
  //                 })
  //             })
  //             .catch((error) => {
  //                 this.setState({
  //                     loading: false
  //                 })
  //             })
  //     }

  // }

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const data = lan.en.howWeDo[0];

    return (
      <>
        <Navbar />

        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <div id="howwedo" className="howwedo">
            <section className="desc">
              <h2 align="center" className="light-text">
                How<span className="highlighted-text"> We</span>
                <span className="light-text"> Do</span>{" "}
              </h2>
              <div className="desc-howwedo">
                <p>{data.description}</p>
                <img className="contact-banner" src={Illustration} alt="" />
              </div>
            </section>

            <h2 align="center" className="highlighted-text">
              Our <span className="light-text">Methodology</span>
            </h2>
            <section className="methodology">
              <div className="method-container">
                <img align="center" src={Methodology} alt="" />
                <p>
                  <br />
                  {data.method.head[0]}
                </p>
                <ul>
                  {data.method.listItems[0]?.map((listItem)=>(<li>{listItem}</li>))}
                </ul>
                <p>
                  <br />
                  <br />
                  {data.method.head[1]}
                </p>
                <ul>
                  {data.method.listItems[1]?.map((listItem)=>(<li>{listItem}</li>))}
                </ul>
              </div>
            </section>
            <section id="services" className="method-stack processing">
              <div className={"row"}>
                <h2 className="light-text" align="center">
                  Technologies We Work On
                </h2>
                <br /> <br />
                <div className="tech-container">
                  {data.tech.map((item) => {
                    return (
                      <div
                        className="box wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div align="center">
                          <img
                            src={item.image}
                            alt=""
                            className="img-fluid"
                            width="100"
                          />
                        </div>{" "}
                        <br />
                        <div align="center">
                          <h4 className="tech_head">{item.name}</h4>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>

          {/* <HowAbout description = {data.description} />
                            <HowMethod method = {data.method}  />
                            <HowTech tech ={data.tech}  />
                      */}

          <Footer />
        </motion.div>
        <MetaTags title={"How We Do | Koderbox"} />
      </>
    );
  }
}

export default Howwedo;
